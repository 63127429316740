import { Injectable } from '@angular/core';
import { AppTranslationGateway } from './app-translation.gateway';
import { forkJoin, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppTranslationService {
  /**
   * Constructor
   * @param gateway gateway de traducción
   */
  constructor(private readonly gateway: AppTranslationGateway) {}

  /**
   * Método para obtener una traducción asincrona
   * @param key clave de la traducción
   * @param params parámetros extras de la traducción
   * @returns traducción
   * */
  getAsync(key: string, params?: Record<string, string>): Observable<string> {
    return this.gateway.getAsync(key, params);
  }

  /**
   * Método para obtener una traducción sincrona
   * @param key clave de la traducción
   * @param params parámetros extras de la traducción
   * @returns traducción
   */
  get(key: string, params?: Record<string, string>): string {
    return this.gateway.get(key, params);
  }

  /**
   * Método para obtener varias traducciones
   * @param dataToTranslate datos a traducir
   * @returns traducciones
   */
  getMany(
    dataToTranslate: { key: string; params?: Record<string, string> }[]
  ): Observable<{ key: string; value: string }[]> {
    const translations$ = dataToTranslate.map((data) =>
      this.getAsync(data.key, data.params)
    );

    return new Observable<{ key: string; value: string }[]>((observer) => {
      forkJoin(translations$).subscribe((translations) => {
        observer.next(
          translations.map((translation, index) => ({
            key: dataToTranslate[index].key,
            value: translation
          }))
        );
        observer.complete();
      });
    });
  }
}
