/**
 * Aqui se pueden definir variables que se cargan solo cuando se ejecuta el proyecto compilado con el comando buildpdn
 */
export const environment = {
  /**
   * indica si el ambiente es productivo, cuando es true se habilita la configuracion de angular para aplicaciones en producción
   */
  production: false,
  useMocks: false,
  identity: {
    useMocks: false
  },
  backend: {
    context: 'https://apidomiciliario.dllosura.com/api_agenda'
  }
};
