import { inject, Injectable } from '@angular/core';
import { AppConfirmationGateway } from './app-confirmation.gateway';
import { AppTranslationService } from '@service/translation/app-translation.service';

/**
 * Servicio de confirmación
 */
@Injectable({
  providedIn: 'root'
})
export class AppConfirmationService {
  /**
   * Servicio de traducción
   */
  private translateService = inject(AppTranslationService);

  /**
   * Constructor
   * @param gateway gateway de confirmación
   */
  constructor(private readonly gateway: AppConfirmationGateway) {}

  /**
   * Método para confirmar una acción
   * @param message mensaje de confirmación
   * @param title título de la confirmación
   * @returns {Promise<boolean>} si se confirmó la acción
   */
  confirm(message: string, title: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.translateService
        .getMany([{ key: title }, { key: message }])
        .subscribe((translations) => {
          const translatedTitle = translations.find(
            (t) => t.key === title
          )!.value;
          const translatedMessage = translations.find(
            (t) => t.key === message
          )!.value;

          resolve(
            this.gateway.confirm({
              title: translatedTitle,
              message: translatedMessage,
              icon: 'pi pi-exclamation-circle'
            })
          );
        });
    });
  }

  /**
   * Método para confirmar la eliminación de un registro
   * @param entity entidad a eliminar
   * @returns {Promise<boolean>} si se confirmó la acción
   */
  confirmDelete(entity: string, id = ''): Promise<boolean> {
    return new Promise((resolve) => {
      this.translateService
        .getMany([
          { key: `${entity}.delete.confirmationTitle`, params: { id } },
          { key: 'notification.confirmationDeleteMessage' }
        ])
        .subscribe((translations) => {
          const translatedTitle = translations.find(
            (t) => t.key === `${entity}.delete.confirmationTitle`
          )!.value;
          const translatedMessage = translations.find(
            (t) => t.key === 'notification.confirmationDeleteMessage'
          )!.value;

          resolve(
            this.gateway.confirm({
              title: translatedTitle,
              message: translatedMessage,
              icon: 'pi pi-exclamation-circle'
            })
          );
        });
    });
  }
}
