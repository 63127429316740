import { AppConfirmation } from './confirmation';

/**
 * Clase que define la interfaz de un gateway para confirmacion de acciones
 */
export abstract class AppConfirmationGateway {
  /**
   * Método para confirmar una acción
   * @param confirmation confirmación de la acción
   * @returns {Promise<boolean>} si se confirmó la acción
   */
  abstract confirm(confirmation: AppConfirmation): Promise<boolean>;
}
