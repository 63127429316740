import { inject, Injectable } from '@angular/core';
import { AppNotificationSeverity } from './app-notification-severity';
import { AppNotificationGateway } from './app-notification.gateway';
import { AppTranslationService } from '@service/translation/app-translation.service';
import { AppErrorItem } from '@domain-utils/app-error-item';

/**
 * Servicio de notificaciones
 */
@Injectable({
  providedIn: 'root'
})
export class AppNotificationService {
  /**
   * Servicio de traducción
   */
  private translateService = inject(AppTranslationService);

  /**
   * Constructor
   * @param notificationGateway gateway de notificaciones
   */
  constructor(private readonly notificationGateway: AppNotificationGateway) {}

  /**
   * Método para mostrar una notificación de éxito
   * @param message  mensaje de la notificación
   */
  success(message: string): void {
    this.translateService
      .getMany([{ key: 'notification.successTitle' }, { key: message }])
      .subscribe((translations) => {
        const title = translations.find(
          (t) => t.key === 'notification.successTitle'
        )!.value;
        const translatedMessage = translations.find(
          (t) => t.key === message
        )!.value;

        this.notificationGateway.notify({
          title: title,
          message: translatedMessage,
          severity: AppNotificationSeverity.SUCCESS
        });
      });
  }

  /**
   * Método para mostrar una notificación de error
   * @param message mensaje de la notificación
   * @param translate si se deben traducir el mensaje
   */
  error(message: string, translate = false): void {
    const messagesToTranslate = translate
      ? [{ key: 'notification.errorTitle' }, { key: message }]
      : [{ key: 'notification.errorTitle' }];
    this.translateService
      .getMany(messagesToTranslate)
      .subscribe((translations) => {
        const title = translations.find(
          (t) => t.key === 'notification.errorTitle'
        )!.value;
        const translatedMessage = translate
          ? translations.find((t) => t.key === message)!.value
          : message;

        this.notificationGateway.notify({
          title: title,
          message: translatedMessage,
          severity: AppNotificationSeverity.ERROR
        });
      });
  }

  /**
   * Método para mostrar una notificación de error con varios mensajes
   * @param messages mensajes de la notificación
   * @param translate si se deben traducir los mensajes, default: false
   */
  errorMany(messages: AppErrorItem[], translate = false): void {
    messages.forEach((message) => {
      this.error(message.message, translate);
    });
  }

  /**
   * Método para mostrar una notificación de advertencia
   * @param message mensaje de la notificación
   */
  warn(message: string): void {
    this.translateService
      .getMany([{ key: 'notification.warningTitle' }, { key: message }])
      .subscribe((translations) => {
        const title = translations.find(
          (t) => t.key === 'notification.warningTitle'
        )!.value;
        const translatedMessage = translations.find(
          (t) => t.key === message
        )!.value;

        this.notificationGateway.notify({
          title: title,
          message: translatedMessage,
          severity: AppNotificationSeverity.WARNING
        });
      });
  }

  /**
   * Método para mostrar una notificación de información
   * @param message mensaje de la notificación
   */
  info(message: string): void {
    this.translateService
      .getMany([{ key: 'notification.infoTitle' }, { key: message }])
      .subscribe((translations) => {
        const title = translations.find(
          (t) => t.key === 'notification.infoTitle'
        )!.value;
        const translatedMessage = translations.find(
          (t) => t.key === message
        )!.value;

        this.notificationGateway.notify({
          title: title,
          message: translatedMessage,
          severity: AppNotificationSeverity.INFO
        });
      });
  }
}
