import { AppNotification } from './app-notification';

/**
 * Gateway de notificaciones
 */
export abstract class AppNotificationGateway {
  /**
   * Método para mostrar una notificación
   * @param message mensaje de la notificación
   */
  abstract notify(notification: AppNotification): void;
}
